.image--decor {
  display: block;
  max-width: 360px;
  opacity: 1;

  &.top {
    width: 40%;
    margin: 10px auto 16px;

    @include query-mobile {
      max-width: 320px;
    }
  }

  &.bottom {
    width: 30%;
    margin: 16px auto;
  }
}
