.chapters-pagination--root {
  &.swiper-pagination-bullets {
    @extend %posa;
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;

    .swiper-pagination-bullet {
      @extend %animate;
      background-color: $color01;
      opacity: 1;

      &-active {
        background-color: $color07;
        width: 64px;
        border-radius: 10px;
      }
    }
  }
}
