.snc-swapping-modal {
  .body {
    text-align: left;
  }

  .snc-swapper--header {
    .helper-wrapper {
      @include posr;
      top: -8px;

      .helper {
        text-align: center;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
  }

  .token--arrow-between {
    @include fz(46);
    text-align: center;
    opacity: 0.65;
  }
}
