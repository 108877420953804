.connectors {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 32px 16px;
  background-color: rgba($modalBackground, 0.5);
  backdrop-filter: blur(2px);
  border-radius: 16px;

  .connector {
    @extend %animate;
    min-height: 52px;
    width: 100%;
    justify-content: flex-start;
    padding-left: 0px;
    max-width: 250px;

    .logo {
      width: 76px;
      margin-right: 8px;
      text-align: right;
    }
  }
}
