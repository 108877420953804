@keyframes confetti-slow {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  
  100% { transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); }
}

@keyframes confetti-medium {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  
  100% { transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); }
}

@keyframes confetti-fast {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  
  100% { transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); }
}

.confe-container {
  height: 80vh;
  position: absolute;
  top: 0px;
  width: 96vw;
  overflow: hidden;
}

.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;
  animation-delay: 850ms;

  &--animation-slow {
    animation: confetti-slow 4.25s linear 1 forwards;
  }
  
  &--animation-medium {
    animation: confetti-medium 3.75s linear 1 forwards;
  }
  
  &--animation-fast {
    animation: confetti-fast 3.25s linear 1 forwards;
  }
}