.glistgroup--ul {
  list-style: none;
  padding: 0;
  margin: 0;

  .group-item {
    @include fz(16);
    background-color: transparent;
    padding: 10px 0;
    cursor: pointer;

    &.current {
      color: $color06;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #405999;
    }
  }
}