.layout--footer {
  &__root {
    box-shadow: 0px -15px 52px rgb(0 0 0 / 25%);

    &.page--journey {
      background: rgb(4, 6, 11);
      background: linear-gradient(
        180deg,
        rgb(50 9 14) 0%,
        rgb(82, 17, 14) 40%,
        rgb(76 25 36) 100%
      );
    }

    .footer-logo {
      &--logo {
        @include size(auto 90px);
        @include fz(1);
        display: block;
        background-image: url($logoPrimary);
        background-size: 65%;
        background-repeat: no-repeat;
        background-position: center center;
        text-indent: -9999px;
        margin: 0 auto 1rem;

        &.mobile {
          @include query-mobile {
            @include size(174px 90px);
            background-size: 96%;
          }

          @include query-mobile-small {
            @include size(124px 80px);
            background-size: 98%;
          }
        }

        @include media-mm(769, 1024) {
          @include size(auto 90px);
          background-size: 85%;
        }

        @include min-desktop {
          @include size(auto 148px);
        }
      }

      &--text {
        @include fz(16);
        color: $color01;
        font-weight: 600;
        margin: 0;

        &.mobile {
          @include fz(13);
          text-align: center;
          font-weight: 500;
        }
      }
    }
  }
}

.footer--primary {
  max-width: 600px;

  @include min-md {
    max-width: $maxHeaderFooterContainer;
  }
}

.footer-content {
  &--wrapper {
    padding-top: 32px;
    padding-bottom: 32px;

    @include min-md {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  &--left {
    @include hidden;
    @include min-769 {
      @include visible;
    }

    @include medium-desktop {
      width: 200px;
    }
  }

  &--right {
    width: 100%;

    .footer-copyright--mobile {
      .footer-copyright--row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin: 16px auto 8px;
      }

      .nav-link {
        @include fz(13);
        color: $color01;
        font-weight: 500;
        padding-left: 0;
        padding-right: 0;
        margin: 0 6px;
      }
    }

    @include min-769 {
      width: auto;

      .footer-copyright--mobile {
        @include hidden;
      }
    }
  }
}

.footer-column {
  &-1,
  &-2 {
    margin-right: 18px;
  }

  &-1 {
    @include hidden;
    margin-right: 0;

    @include min-769 {
      @include visible;
    }
  }

  &-3 {
    margin-right: 1rem;

    @include min-769 {
      margin-right: 0;
    }
  }

  &-4 {
    @include min-769 {
      @include hidden;
    }
  }
}

.footer-columns {
  &--wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    a {
      @include fz(14);
      color: $color01;
      font-weight: 500;

      &:hover,
      &:focus {
        color: $color07;
        text-decoration: underline;
      }

      @include min-md {
        @include fz(16);
        font-weight: 600;
      }
    }

    .navbar-nav {
      .nav-link {
        padding: 2px 0;
      }
    }

    @include min-769 {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;

      .footer-column {
        &-2 {
          border-left: 2px solid $color01;
          margin-left: 60px;
          margin-right: 60px;
          padding-left: 60px;
        }
      }
    }

    @include medium-desktop {
      .footer-column {
        &-2 {
          margin-left: 40px;
          margin-right: 40px;
          padding-left: 40px;
        }
      }
    }
  }
}

.footer-social-links {
  &--wrapper {
    display: flex;
    flex-flow: column nowrap;
    margin: 1.5rem auto 0;
    text-align: center;

    .text {
      @include fz(14);
      color: $color01;
      font-weight: 600;
      margin: 0 auto 20px;
    }

    .social-links {
      justify-content: center;
    }

    .social-link {
      @extend %animate;
      @include size(40px);
      @include gradient01;
      border-radius: 15px;
      line-height: 40px;
      padding: 0;
      text-align: center;
      transition-property: transform, color;
      will-change: transform, color;

      &:not(:last-child) {
        margin-right: 24px;
      }

      &--logo {
        width: 22px;
      }

      &.fb {
        .social-link--logo {
          @include size(22px 26px);
        }
      }
    }

    @include min-769 {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;

      .text {
        @include fz(18);
        margin: 0 24px 0 0;
      }

      .social-link {
        @include size(48px);
        @include onHoldAndScaleDown;
        line-height: 48px;

        &--logo {
          width: 28px;
        }

        &.fb {
          .social-link--logo {
            @include size(24px 30px);
          }
        }
      }
    }

    @include medium-desktop {
      .text {
        @include fz(14);
      }
    }
  }
}
