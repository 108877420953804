.btn--modal-maximize {
  @include posa;
  @include size(22px);
  background-image: url('../../images/icon--maximize.svg');
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  right: 16px;
  top: 22px;
  border: none;
  outline: none;
  background-color: transparent;

  @include min-md {
    top: 16px;
  }
}

.modal--chapter-fullscreen-viewer {
  .modal {
    &-header {
      border-bottom: none;
      box-shadow: 0px 11px 47px rgba(0, 0, 0, 0.5);
    }

    &-content {
      background-color: $color03;
      background-image: url('../../images/img--grave-background.webp');
      background-position: center bottom;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}
