.egg-evolve-dragon-confirm {
  &--modal {
    .modal-content {
      background-image: url('../../images/story--next.webp'),
        radial-gradient(circle at 50% 50%, rgb(77 65 65) 0%, rgb(19 18 22) 100%);
      background-position: center center, center center;
      background-size: cover, 100%;
      background-repeat: no-repeat;
      background-blend-mode: multiply, normal;

      &::before,
      &::after {
        position: absolute;
        content: '';
        display: block;
        height: 130px;
        width: 100%;
      }

      &::before {
        top: 0;
        background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0));
      }

      &::after {
        bottom: 0;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
      }
    }

    .modal-header {
      padding: 0;
    }

    .modal-body {
      backdrop-filter: blur(1.5px);
      z-index: 2;
    }

    .modal-dialog {
      max-width: 520px;
    }

    .body-wrapper {
      @include posr;
      @include size(100%);
      align-items: center;
      background-image: url('../../images/bg--make-dragon.png');
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      margin: 0 auto 48px;
      min-height: 450px;
      width: 88%;
    }

    .actions {
      @include posr;
      margin-top: 16px;

      .icon--how-to-merge {
        @include fz(28);
        @include size(42px);
        @include posa;
        background: transparent;
        border: none;
        color: $color01;
        left: 50%;
        line-height: 1;
        top: 50%;
        transform: translateX(135px) translateY(-50%);
      }
    }
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
  10% {
    transform: translateY(-3px) translateX(-2px) rotate(3deg);
  }
  20% {
    transform: translateY(3px) translateX(2px) rotate(-3deg);
  }
  30% {
    transform: translateY(-2px) translateX(-1px) rotate(2deg);
  }
  40% {
    transform: translateY(2px) translateX(1px) rotate(-2deg);
  }
  50% {
    transform: translateY(-1px) translateX(0) rotate(1deg);
  }
  60% {
    transform: translateY(1px) translateX(0) rotate(-1deg);
  }
  70% {
    transform: translateY(-1px) translateX(0) rotate(1deg);
  }
  80% {
    transform: translateY(1px) translateX(0) rotate(-1deg);
  }
  90% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
}

.egg-evolve-dragon {
  &--egg {
    animation: shake 1.2s infinite ease-in-out;
    transform: translateY(10px);
    width: 200px;
  }

  &--dragon-mock-wrapper {
    @include posa;
    transform-origin: 50% 50%;
    left: 50%;
    top: 50%;
  }

  &--dragon-mock {
    max-width: 172px;
    max-height: 172px;
    object-fit: cover;
    padding: 5px;
    background-image: radial-gradient(
      ellipse farthest-corner at center top,
      #dfc688 0%,
      #422b24 100%
    );
    border-radius: 50%;
    box-shadow: $bs01;
  }

  &--dragon-mock-background {
    max-width: 240px;
    z-index: 20;

    &.Fire {
      top: 45%;
    }
  }

  &--effect-layer {
    @include posa;
    left: 50%;
    transform-origin: 50% 50%;
    max-width: 500px;
    max-height: 500px;
    object-fit: cover;

    &.dragon-hatch {
      top: 48%;
      transform: translate(-50%, -50%) scale(1.85);
      // transition-duration: 12s;
    }

    &.default {
      transition-duration: 2s;
      top: 50%;
      mix-blend-mode: lighten;
      transform: translate(-50%, -50%) scale(1);
      max-width: 340px;
      z-index: -1;
    }
  }
}
