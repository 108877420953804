.new-icon-notifications--modal {
  .body {
    text-align: center;

    img {
      max-width: 96px;
    }

    .text {
      max-width: 90%;
      margin: 0 auto 24px;
    }
  }
}

.checkbox--never-remind {
  .btn--checkbox-type {
    margin-left: auto;
    margin-right: auto;
  }
}
