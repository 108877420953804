.panel {
  background-color: $color22;
  padding: 24px;
  border-radius: 6px;
}

.box-info {
  &--root {
    &.traits-values {
      @include posa;
      @include size(112px);
      @include fz(11);
      background-image: url('../../images/img--bg-unique-square.webp');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      backdrop-filter: blur(1.5px);
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
      font-weight: 600;
      justify-content: center;
      text-align: center;

      @include min-md {
        @include fz(14);
      }

      &.long-value {
        @include size(148px);
      }

      .label {
        @include fz(12);
      }
      .value {
        @include fz(13);
      }

      &:after,
      &:before {
        @include posa;
        content: '';
        background-color: white;
      }

      &:before {
        box-shadow: $bs01;
      }

      &.trait--body-elemental {
        left: -160px;
        bottom: 90px;

        &:after {
          right: -110px;
          top: 49%;
          width: 120px;
          height: 2px;

          transform: translateY(-50%);
        }

        &:before {
          right: -110px;
          top: 47%;
          width: 100px;
          height: 2px;
          transform: rotate(-115deg) translateY(-50%);
          transform-origin: right center;
        }
      }

      &.trait--mouth-elemental {
        right: -100px;
        bottom: -20px;

        &:after {
          left: -57px;
          top: 49%;
          width: 60px;
          height: 2px;
          transform: translateY(-50%);
        }

        &:before {
          left: -56px;
          top: 48%;
          width: 130px;
          height: 2px;
          transform: rotate(-110deg) translateY(-50%);
          transform-origin: left center;
        }
      }

      &.trait--mane-elemental {
        left: -110px;
        top: -80px;

        &:after {
          right: -56px;
          top: 49%;
          width: 60px;
          height: 2px;
          transform: translateY(-50%);
        }

        &:before {
          right: -55px;
          top: 47%;
          width: 125px;
          height: 2px;
          transform: rotate(-115deg) translateY(-50%);
          transform-origin: right center;
        }
      }

      &.trait--eyes {
        right: -75px;
        top: -45px;

        &:after {
          left: -45px;
          top: 49%;
          width: 50px;
          height: 2px;
          transform: translateY(-50%);
        }

        &:before {
          left: -45px;
          top: 47%;
          width: 170px;
          height: 2px;
          transform: rotate(-255deg) translateY(-50%);
          transform-origin: left center;
        }
      }

      &.trait--nose {
        right: -205px;
        bottom: 170px;

        &:after {
          left: -186px;
          top: 49%;
          width: 190px;
          height: 2px;
          transform: translateY(-50%);
        }

        &:before {
          left: -186px;
          top: 47%;
          width: 20px;
          height: 2px;
          transform: rotate(120deg) translateY(-50%);
          transform-origin: left center;
        }
      }

      &.trait--fangs {
        left: -100px;
        bottom: -100px;

        &:after {
          right: -50px;
          top: 49%;
          width: 60px;
          height: 2px;
          transform: translateY(-50%);
        }

        &:before {
          right: -48px;
          top: 48%;
          width: 210px;
          height: 2px;
          transform: rotate(-240deg) translateY(-50%);
          transform-origin: right center;
        }
      }
    }
    &.value--large {
      .value {
        @include fz(18);
        min-height: 27px;
        font-weight: 600;
      }
    }

    &.is-horizontal {
      @include flexRow;
      align-items: center;

      .label {
        margin-right: 8px;
      }
    }

    .label {
      @include fz(14);
      color: $color21;
    }

    .value {
      @include fz(15);
      min-height: 24px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
}
