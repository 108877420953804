/**
  Bootstrap 5 Grid breakpoints:
  $grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
  );
*/

@mixin media-mm($min, $max) {
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px) {
    @content;
  }
}

@mixin media-min($min) {
  @media screen and (min-width: #{$min}px) {
    @content;
  } 
}

@mixin min-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin min-769 {
  @media screen and (min-width: 769px) {
    @content;
  }
}

@mixin medium-desktop {
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    @content;
  }
}

@mixin min-desktop {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin large-desktop {
  @media screen and (min-width: 2056px) {
    @content;
  }
}

@mixin query-mobile-small {
  @media screen and (max-width: 360px) {
    @content;
  }
}

@mixin query-mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin query-timeline--vertical {
  @media screen and (min-width: 440px) and (max-width: 1024px)  {
    @content;
  }
}

@mixin query-timeline--horizontal {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin query-timeline--horizontal-special {
  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    @content;
  }
}

@mixin min-sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin max-sm {
  @media screen and (max-width: 575px) {
    @content;
  }
}

@mixin with-reduced-motion {
  @media screen and (prefers-reduced-motion: reduce) { 
    @content;
  }
}