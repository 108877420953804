
/** Block Members **/

.block--members {
  padding-top: 40px;
  padding-bottom: 40px;

  .heading {
    margin-bottom: 2rem;
  }

  @include min-md {
    padding-top: 65px;
    padding-bottom: 65px;

    .heading {
      margin-bottom: 4rem;
    }
  }
}

.members-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem 16px;

  &:hover {
    .grid-item {
      &--square {
        max-width: 240px;

        .grid-item--image {
          transform: scale(.8);
        }
      }

      &:hover {
        .grid-item--image {
          transform: scale(1.25);
        }
      }
    }
  }

  @include min-md {
    &.logo-total--3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.logo-total--4 {
      grid-template-columns: repeat(4, 1fr);
    }

    &.logo-total--5 {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .grid-item {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;

    &--logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      height: auto;
      flex: 1 1 0px;
    }

    &--square {
      @extend %animate;
      background-image: url('../../images/img--bg-unique-square.webp');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 18px;
      max-width: 260px;
      padding-bottom: 100%;
      position: relative;
      transition-duration: 800ms;
      transition-timing-function: cubic-bezier(0, 1, 0.3, 1);
      width: 100%;
    }

    &--link {
      display: block;
    }

    &--image-box {
      overflow: hidden;
      border-radius: 24px;
      bottom: 0;
      display: block;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      margin: auto;
      max-height: 180px;
      position: absolute;
      right: 0;
      top: 0;
    }

    &--image {
      @extend %posa;
      @extend %animate;
      transition-duration: 800ms;
      transition-timing-function: cubic-bezier(0, 1, 0.3, 1);
      border-radius: 24px;
      bottom: 0;
      display: block;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      margin: auto;
      max-height: 180px;
      position: absolute;
      right: 0;
      top: 0;

      @include query-timeline--vertical {
        max-height: 110px;
      }
    }

    &--name {
      margin-top: auto;

      .name {
        @include font2;
        @include fz(26);
        font-weight: 600;
        margin-bottom: 0;
      }

      .role {
        @include fz(15);
        font-weight: 400;
      }
    }

    &--social-links {
      display: flex;
      flex-flow: row nowrap;
      margin-top: 1rem;
      justify-content: center;

      .social-link--item {
        &:hover {
          .social-link {
            transform: translateY(-10px);
          }
        }
      }

      .nav-link {
        @extend %animate;
        @include onHoldAndScaleDown;
        @include size(42px);
        @include gradient02;
        border-radius: 15px;
        line-height: 42px;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: 0;
        text-align: center;
        transition-property: box-shadow, transform, color;
        will-change: box-shadow, transform, color;

        &:hover {
          box-shadow: 0px 0px 25px $color08;
        }

        .social-link--logo {
          max-width: 28px;
        }
      }
    }
  }
}