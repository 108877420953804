@mixin generateNFTStatus {
  @include fz(12);
  background-color: $color08;
  border-radius: 16px;
  color: $color03;
  display: inline-block;
  line-height: 1;
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: 600;

  &.is-hatching,
  &.is-minted,
  &.is-minting {
    background-color: $color13;
    color: $color10;
  }

  &.is-failed,
  &.is-stopped {
    background-color: $color05;
    color: $color10;
  }
}

$c_0: #000000;
$c_1: #253247;

@mixin effectFlipGlossy {
  perspective: 1000px;

  // Shadows
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background: rgba($c_0, 0.5);
    box-shadow: 0 6px 12px 12px rgba($c_0, 0.4);
    will-change: opacity;
    transform-origin: top center;
    transform: skewX(0.001deg);
    transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
  }

  &:hover {
    &::before {
      opacity: 0.6;
      transform: rotateX(7deg) translateY(-6px) scale(1.05);
    }

    .nft-grid-item--media {
      transform: rotateX(7deg) translateY(-6px);

      &::after {
        transform: translateY(0%);
      }
    }
  }

  .nft-grid-item--background {
    @include posr(110);
  }

  .nft-grid-item--media {
    perspective-origin: 50% 50%;
    transform-style: preserve-3d;
    transform-origin: top center;
    will-change: transform;
    transform: skewX(0.001deg);
    transition: transform 0.3s ease-in-out;

    &::after {
      display: block;
      content: '';
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 120%;
      background: radial-gradient(
        circle at 10% 20%,
        rgb(69, 86, 102) 0%,
        rgb(34, 34, 34) 90%
      );
      transform: translateY(-20%);
      will-change: transform;
      transition: transform 0.4s cubic-bezier(0.18, 0.9, 0.58, 1);
    }
  }
}

@mixin generateNFTElementBackground {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #283a6e 28.65%,
    rgba(7, 26, 82, 0) 100%
  );

  &.bg--water {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #283a6e 28.65%,
      rgba(7, 26, 82, 0) 100%
    );
  }

  &.bg--fire {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(78, 34, 21, 0.5) 28.65%,
      rgba(7, 26, 82, 0) 100%
    );
  }

  &.bg--wood {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #4b3410 28.65%,
      rgba(7, 26, 82, 0) 100%
    );
  }

  &.bg--metal {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #481f50 28.65%,
      rgba(7, 26, 82, 0) 100%
    );
  }

  &.bg--earth {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #2a5a21 28.65%,
      rgba(7, 26, 82, 0) 100%
    );
  }
}
