.flipping-card {
  &--loading {
    @extend %posa;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--cards {
    @include flexRow;
    column-gap: 1.5rem;
    flex-flow: row nowrap;
    padding-left: 16px;
    padding-right: 16px;
  }

  &--card-wrapper {
    @extend %posr;
  }

  &--card {
    @include size(100%);
    border-radius: 34px;
    cursor: pointer;
    will-change: transform, opacity;
    background-size: cover;

    &.back {
      position: absolute;

      &.active {
        box-shadow: $bs03;
      }
    }

    img {
      @extend %animate;
      border-radius: 34px;
      display: block;
      max-width: 100%;

      &:hover {
        box-shadow: $bs03;
      }
    }
  }

  &--container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  &--lucky-slot {
    @extend %posa;
    color: $color01;
    background-color: $modalBackground;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 32px;
    border-radius: 24px;
  }
}

.error-box--inline {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 12px;

  .icon {
    @include fz(48);
    color: $color08;
  }

  .btn-error--close {
    margin-top: 16px;
  }
}

